.react-datepicker {
  width: 300px;

}

.react-datepicker__header {
  width: 300px;
  font-size: 20px;
}

.react-datepicker__month {
  font-size: 17px;
  margin-bottom: 20px;
 
}

.react-datepicker__day-name,
.react-datepicker__day {
  font-size: 16px;
  margin: 5px;

}

.react-datepicker__current-month {
  font-size: 20px; 

}

.react-datepicker {
  width: 330px;
}
.react-datepicker__header {
  width: 326px;
  font-size: 20px;
}
.react-datepicker__month {
  font-size: 13px;
  margin-right: 17px;
}
.react-datepicker__day-name {
  font-size: 17px;
  margin-right: 20px;

}
.react-datepicker__day {
  margin-left: 15px;
  width: 22px;
  padding: 1px;
  
}
.react-datepicker__current-month {
  font-size: 20px;
  
}


