/*====================================================================*/
/*  Fonts
/*====================================================================*/

$font-inter-regular: 'Inter-Regular', sans-serif;
$font-inter-light: 'Inter-Light', sans-serif;
$font-inter-extralight: 'Inter-ExtraLight', sans-serif;
$font-inter-thin: 'Inter-Thin', sans-serif;
$font-inter-semibold: 'Inter-SemiBold', sans-serif;
$font-inter-bold: 'Inter-Bold', sans-serif;
$font-inter-extrabold: 'Inter-ExtraBold', sans-serif;

$font-primary-regular: $font-inter-regular;
$font-primary-light: $font-inter-light;
$font-primary-extralight: $font-inter-extralight;
$font-primary-thin: $font-inter-thin;
$font-primary-semibold: $font-inter-semibold;
$font-primary-bold: $font-inter-bold;
$font-primary-extrabold: $font-inter-extrabold;

/*====================================================================*/
/*  Colors
/*====================================================================*/

$lavender: #EDEAFA;
$quartz: #D5CDF4;
$lavender-blue: #C7BDF5;
$medium-purple: #9583E4;
$purple-heart: #4120CE;
$paua: #1C0E58;
$persian-indigo: #2E1B7E;
$jaguar: #0E072C;
$white: #FFFFFF;
$white-smoke: #F5F5F5;
$gainsboro: #E0E0E0;
$very-light-grey: #CCCCCC;
$dark-grey: #A3A3A3;
$dim-grey: #666666;
$nero: #292929;
$black: #000000;
$caramel: #FFD599;
$orange-peel: #FF9700;
$dark-goldenrod: #CC7900;
$onahau: #C0EFF1;
$light-sea-green: #29B5BC;
$atoll: #1B797E;
$your-pink: #F8BBBA;
$harley-davidson-orange: #DB1A17;
$falu-red: #8A100F;
$manatee: #8A92A6;

/*====================================================================*/
/*  App Colors
/*====================================================================*/

$color-primary-light: $lavender-blue;
$color-primary-base: $purple-heart;
$color-primary-dark: $paua;

$color-secondary-lighter: $quartz;
$color-secondary-light: $lavender;
$color-secondary-base: $medium-purple;
$color-secondary-dark: $persian-indigo;
$color-secondary-darker: $jaguar;

$color-neutral-lightest: $white;
$color-neutral-lighter: $white-smoke;
$color-neutral-light: $gainsboro;
$color-neutral-base: $very-light-grey;
$color-neutral-dark: $dark-grey;
$color-neutral-darker: $dim-grey;
$color-neutral-darkest: $nero;
$color-neutral-alternative: $manatee;

$color-highlight-light: $caramel;
$color-highlight-base: $orange-peel;
$color-highlight-dark: $dark-goldenrod;

$color-helper-light: $onahau;
$color-helper-base: $light-sea-green;
$color-helper-dark: $atoll;

$color-warning-light: $your-pink;
$color-warning-base: $harley-davidson-orange;
$color-warning-dark: $falu-red;

/*====================================================================*/
/*  Themes
/*====================================================================*/

$themes: (
		light: (
			primary-bg: $color-neutral-lightest,
			secondary-bg: $color-neutral-lighter,
			primary-text: $color-neutral-darkest,
			primary-border: $color-neutral-light,

			box-shadow: $color-neutral-lighter,
			menu-hover: $color-secondary-light,
			menu-selected: $color-secondary-light,

			btn-primary-bg: $color-primary-base,
			btn-primary-text: $color-neutral-lightest,
			btn-primary-hover: $color-primary-dark,
			btn-primary-disabled-bg: $color-neutral-lighter,
			btn-primary-disabled-text: $color-neutral-darker,

			btn-secondary-bg: $color-neutral-lightest,
			btn-secondary-text: $color-primary-base,
			btn-secondary-border: $color-primary-base,
			btn-secondary-hover: $color-primary-light,
			btn-secondary-disabled-bg: $color-neutral-lightest,
			btn-secondary-disabled-border: $color-neutral-darker,
			btn-secondary-disabled-text: $color-neutral-darker,

			btn-tertiary-bg: transparent,
			btn-tertiary-text: $color-primary-base,
			btn-tertiary-hover: $color-primary-light,
			btn-tertiary-disabled-bg: transparent,
			btn-tertiary-disabled-text: $color-neutral-darker,

			btn-user-profile-avatar-border: $color-neutral-light,
			btn-user-profile-avatar-border-hover: $color-primary-base,
			btn-user-profile-avatar-bg: $color-primary-base,
			btn-user-profile-avatar-fill: $color-neutral-lightest,
			btn-user-profile-icon: $color-neutral-darkest,
			btn-user-profile-icon-active: $color-primary-base,

			btn-tb-pagination-border: $color-neutral-darker,
			btn-tb-pagination-bg: $color-primary-base,
			btn-tb-pagination-bg-hover: $color-primary-dark,
			btn-tb-pagination-bg-disabled: $color-neutral-lightest,
			btn-tb-pagination-icon: $color-neutral-lightest,
			btn-tb-pagination-icon-hover: $color-neutral-lightest,
			btn-tb-pagination-icon-disabled: $color-neutral-darker,

			btn-page-title-bg: $color-secondary-light,
			btn-page-title-icon: $color-primary-base,

			btn-modal-hover: $color-secondary-light,
			btn-modal-icon: $color-neutral-darkest,

			tb-header-bg: $color-neutral-light,
			tb-bg: $color-neutral-lightest,
			tb-text: $color-neutral-darkest,
			tb-item-border: $color-neutral-light,
			tb-status-active: $color-helper-light,
			tb-status-inactive: $color-neutral-lighter,
			tb-status-text: $color-neutral-darkest,
			tb-search-input-border: $color-neutral-light,
			tb-row-hover-bg: $color-primary-light,
			tb-row-hover-text: $color-primary-base,

			input-bg: $color-neutral-lightest,
			input-disabled-bg: $color-neutral-lighter,
			input-label : $color-neutral-darkest,
			input-focus: $color-primary-base,

			form-tab-text: $color-neutral-darker,
			form-tab-active: $color-primary-base,

			icon-home-bg: $color-primary-base,
			icon-home-fill: $color-neutral-lightest,
			icon-table-bg: $color-secondary-light,
			icon-table-fill: $color-primary-base,
			icon-table-avatar-bg: $color-neutral-lighter,
			icon-table-avatar-stroke: $color-neutral-darker,
			icon-search: $color-neutral-darker,
			icon-table-checkbox-checked: $color-primary-base,
			icon-table-checkbox: $color-neutral-light,
			icon-table-checkbox-bg: $color-neutral-lightest,
			icon-select: $color-neutral-darkest,
			icon-toast-error-bg: $color-warning-base,
			icon-toast-error: $color-neutral-lightest,
			switch-track-bg: $color-neutral-lightest,
			switch-base: $color-primary-base,
			switch-base-checked: $color-neutral-lightest,

			sidebar-bg: $color-neutral-lightest,
			sidebar-text: $color-neutral-alternative,
			sidebar-icon: $color-neutral-alternative,
		),

		dark: (
			primary-bg: $color-neutral-darkest,
			secondary-bg: $color-neutral-darkest,
			primary-text: $color-neutral-lightest,
			primary-border: $color-neutral-darker,

			box-shadow: $color-neutral-darkest,
			menu-hover: $color-neutral-darker,
			menu-selected: $color-neutral-darker,

			btn-primary-bg: $color-neutral-lightest,
			btn-primary-text: $color-primary-base,
			btn-primary-hover: $color-primary-light,
			btn-primary-disabled-bg: $color-neutral-darker,
			btn-primary-disabled-text: $color-neutral-lightest,

			btn-secondary-bg: $color-neutral-darkest,
			btn-secondary-text: $color-neutral-lightest,
			btn-secondary-border: $color-neutral-lightest,
			btn-secondary-hover: $color-primary-base,
			btn-secondary-disabled-bg: $color-neutral-darkest,
			btn-secondary-disabled-border: $color-neutral-dark,
			btn-secondary-disabled-text: $color-neutral-dark,

			btn-tertiary-bg: transparent,
			btn-tertiary-text: $color-neutral-lightest,
			btn-tertiary-hover: $color-primary-base,
			btn-tertiary-disabled-bg: transparent,
			btn-tertiary-disabled-text: $color-neutral-dark,

			btn-user-profile-avatar-border: $color-neutral-darker,
			btn-user-profile-avatar-border-hover: $color-primary-light,
			btn-user-profile-avatar-bg: $color-primary-light,
			btn-user-profile-avatar-fill: $color-neutral-lightest,
			btn-user-profile-icon: $color-neutral-lightest,
			btn-user-profile-icon-active: $color-primary-light,

			btn-tb-pagination-border: $color-neutral-darker,
			btn-tb-pagination-bg: $color-neutral-lightest,
			btn-tb-pagination-bg-hover: $color-primary-light,
			btn-tb-pagination-bg-disabled: $color-neutral-darkest,
			btn-tb-pagination-icon: $color-primary-base,
			btn-tb-pagination-icon-hover: $color-primary-base,
			btn-tb-pagination-icon-disabled: $color-neutral-darker,

			btn-page-title-bg: $color-neutral-base,
			btn-page-title-icon: $color-primary-base,

			btn-modal-hover: $color-neutral-darker,
			btn-modal-icon: $color-neutral-lightest,

			tb-header-bg: $color-neutral-darker,
			tb-bg: $color-neutral-darkest,
			tb-text: $color-neutral-lightest,
			tb-item-border: $color-neutral-darker,
			tb-status-active: $color-helper-dark,
			tb-status-inactive: $color-neutral-darker,
			tb-status-text: $color-neutral-lightest,
			tb-search-input-border: $color-neutral-darker,
			tb-row-hover-bg: $color-primary-light,
			tb-row-hover-text: $color-primary-base,

			input-bg: $color-neutral-darkest,
			input-disabled-bg: $color-neutral-dark,
			input-label: $color-neutral-lightest,
			input-focus: $color-primary-light,

			form-tab-text: $color-neutral-light,
			form-tab-active: $color-primary-light,

			icon-home-bg: $color-neutral-base,
			icon-home-fill: $color-primary-base,
			icon-table-bg: $color-neutral-base,
			icon-table-fill: $color-primary-base,
			icon-table-avatar-bg: $color-neutral-base,
			icon-table-avatar-stroke: $color-primary-base,
			icon-search: $color-neutral-light,
			icon-table-checkbox-checked: $color-primary-base,
			icon-table-checkbox: $color-neutral-darker,
			icon-table-checkbox-bg: $color-neutral-darkest,
			icon-select: $color-neutral-lightest,
			icon-toast-error-bg: $color-neutral-base,
			icon-toast-error: $color-warning-base,
			switch-track-bg: $color-primary-light,
			switch-base: $color-primary-light,
			switch-base-checked: $color-neutral-darkest,

			sidebar-bg: $color-neutral-darkest,
			sidebar-text: $color-neutral-lightest,
			sidebar-icon: $color-neutral-lightest,
		)
	);