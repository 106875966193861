@use "../4-helpers" as *;

%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.4rem;
  text-transform: none;
  cursor: pointer;
}

.btn-primary {
  @extend %btn;
  @include themed() {
    background-color: t("btn-primary-bg");
    color: t("btn-primary-text");
  }

  &:hover {
    @include themed() {
      background-color: t("btn-primary-hover");
    }
  }

  &:disabled {
    @include themed() {
      background-color: t("btn-primary-disabled-bg");
      color: t("btn-primary-disabled-text");
    }
  }

  &--large {
    padding: 1.6rem 3.2rem;
    width: fit-content;
  }

  &--medium {
    padding: 1.2rem 2.4rem;
  }

  &--large-full-width {
    padding: 1.6rem 0;
    width: 100%;
  }

  &--margin-left {
    margin-left: 1.2rem;
  }

  &--margin-top {
    margin-top: 1.6rem;
  }

  &--margin-top-small {
    margin-top: 1.2rem;
  }
}

.btn-secondary {
  @extend %btn;
  @include themed() {
    background-color: t("btn-secondary-bg");
    border: 0.1rem solid t("btn-secondary-border");
    color: t("btn-secondary-text");
  }

  &:hover {
    @include themed() {
      background-color: t("btn-secondary-hover");
      border: 0.1rem solid t("btn-secondary-border");
    }
  }

  &:disabled {
    @include themed() {
      background-color: t("btn-secondary-disabled-bg");
      border-color: t("btn-secondary-disabled-border");
    }
  }

  &--large {
    padding: 1.6rem 3.2rem;
    width: fit-content;
  }

  &--medium {
    padding: 1.2rem 2.4rem;
  }

  &--large-full-width {
    padding: 1.6rem 0;
    width: 100%;
  }

  &--margin-right-auto {
    margin-right: auto;
  }

  &--margin-right {
    margin-right: 1.2rem;
  }

  &--margin-right-18 {
    margin-right: 1.8rem;
  }

  &--margin-left {
    margin-left: 1.2rem;
  }

  &--margin-left-auto {
    margin-left: auto;
  }

  &--margin-top-2 {
    margin-top: 2rem;
  }

  &--margin-top-3 {
    margin-top: 3.2rem;
  }

  &--select {
    padding-right: 4.8rem;
  }
}

.btn-tertiary {
  @extend %btn;
  @include themed() {
    background-color: t("btn-tertiary-bg");
    color: t("btn-tertiary-text");
  }

  &:hover {
    @include themed() {
      background-color: t("btn-tertiary-hover");
    }
  }

  &--large {
    padding: 1.6rem 3.2rem;
    width: fit-content;
  }

  &--large-full-width {
    padding: 1.6rem 0;
    width: 100%;
  }

  &--medium {
    padding: 1.2rem 2.4rem;
    width: fit-content;
  }

  &--medium-full-width {
    padding: 1.2rem 0;
    width: 100%;
  }

  &--margin-left {
    margin-left: 1.2rem;
  }

  &--margin-right {
    margin-right: 1.2rem;
  }

  &--margin-top {
    margin-top: 0.5rem;
  }
}

.btn-link {
  @extend %btn;
  background-color: transparent;
  color: $color-primary-base;
  padding: 0;

  &:hover {
    color: $color-primary-dark;
    background-color: transparent;
  }

  &--margin-left {
    margin-left: 0.5rem;
  }

  &--margin-right {
    margin-right: 0.5rem;
  }

  &--margin-top {
    margin-top: 0.5rem;
  }
}

.btn-icon {
  margin-left: -4rem;
  vertical-align: middle;
  border: none;
  background-color: transparent;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  &--tenant-selector {
    margin-top: -0.5rem;
  }
}

.btn-modal {
  margin-left: auto;
  border: none;
  background-color: transparent;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  padding: 0;
  min-width: 0;
  cursor: pointer;

  &:hover {
    @include themed() {
      background-color: t("btn-modal-hover");
    }
  }
}

.btn-page-title {
  border: none;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  min-width: 0;
  border-radius: 50%;

  @include themed() {
    background-color: t("btn-page-title-bg");
  }
}

.btn-sidebar {
  min-width: 0;
  width: 3rem;
  height: 3rem;
  z-index: 1250;
  border-radius: 50%;
  position: fixed;
  top: 2.2rem;
  background-color: $color-primary-base;

  &--open {
    margin-left: 22.5rem;
    transition: margin-left 0.3s;
  }

  &--closed {
    margin-left: 6.6rem;
    transition: margin-left 0.3s;
  }

  &:hover {
    background-color: $color-primary-base;
  }
}

.btn-user-profile {
  height: 4.2rem;

  &:hover {
    background-color: transparent;
    .avatar-border {
      @include themed() {
        border: 0.2rem solid t("btn-user-profile-avatar-border-hover");
      }
    }

    .icon--closed-menu {
      @include themed() {
        color: t("btn-user-profile-icon-active");
      }
    }
  }
}

.c-btn-loading {
  text-align: left;
  width: 14rem;

  span {
    height: 2.4rem;
    vertical-align: middle;
  }
}

.btn-loading {
  margin-left: 1.6rem;
  &:after {
    animation: dots 1.5s infinite;
    content: "";

    @keyframes dots {
      25% {
        content: ".";
      }
      65% {
        content: "..";
      }
      85% {
        content: "...";
      }
    }
  }
}

.test-style {
  padding: 1.2rem 5.2rem 1.2rem 2.4rem;
}

.btn-icon {
  margin-left: -4rem;
  vertical-align: middle;
  border: none;
  background-color: transparent;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.btn-modal {
  margin-left: auto;
  border: none;
  background-color: transparent;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  padding: 0;
  min-width: 0;
  cursor: pointer;

  &:hover {
    @include themed() {
      background-color: t("btn-modal-hover");
    }
  }
}

.btn-page-title {
  border: none;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  min-width: 0;
  border-radius: 50%;

  @include themed() {
    background-color: t("btn-page-title-bg");
  }
}

.btn-sidebar {
  min-width: 0;
  width: 3rem;
  height: 3rem;
  z-index: 1250;
  border-radius: 50%;
  position: fixed;
  top: 2.2rem;
  background-color: $color-primary-base;

  &--open {
    margin-left: 22.5rem;
    transition: margin-left 0.3s;
  }

  &--closed {
    margin-left: 6.6rem;
    transition: margin-left 0.3s;
  }

  &:hover {
    background-color: $color-primary-base;
  }
}

.btn-user-profile {
  height: 4.2rem;

  &:hover {
    background-color: transparent;
    .avatar-border {
      @include themed() {
        border: 0.2rem solid t("btn-user-profile-avatar-border-hover");
      }
    }

    .icon--closed-menu {
      @include themed() {
        color: t("btn-user-profile-icon-active");
      }
    }
  }
}

.c-btn-loading {
  text-align: left;
  width: 14rem;

  span {
    height: 2.4rem;
    vertical-align: middle;
  }
}

.btn-loading {
  margin-left: 1.6rem;
  &:after {
    animation: dots 1.5s infinite;
    content: "";

    @keyframes dots {
      25% {
        content: ".";
      }
      65% {
        content: "..";
      }
      85% {
        content: "...";
      }
    }
  }
}

.test-style {
  padding: 1.2rem 5.2rem 1.2rem 2.4rem;
}

.btn-table-edit {
  @extend %btn;
  background-color: $color-secondary-light;
  border: 0.1rem solid $color-neutral-light;
  height: 3.5rem;
  margin-right: 1.5rem;

  &:hover {
    background-color: $color-secondary-light;
  }
}

.btn-table {
  @extend %btn;
  display: inline-flex;
  padding: 0.6rem 0.8rem 0.7rem 0.7rem;
  justify-content: center;
  align-items: center;
  height: 3.2rem;

  border-radius: 0.4rem;
  border: 0.1rem solid rgb(219, 26, 23);

  background: $color-neutral-lightest;

  cursor: pointer;

  &:hover {
    background: $color-neutral-lightest;
    border-color: rgb(219, 26, 23);
  }

  .icon {
    width: 1.6rem;
    height: 1.5rem;

    justify-content: center;
    align-items: center;
  }
}

p.delete-btn-text {
  color: #db1a17;
  padding-left: 0.8rem;
}

.btn-Details {
  padding: 0;
  p {
    text-transform: none;
    color: #4120ce;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-decoration-line: underline;
    text-underline-offset: 2px;
  }
}

.table-btn {
  width: 32.8rem;
  height: 4rem;
  margin-bottom: -0.7rem;
}

.hider-btn {
  margin-bottom: -0.7rem;
  height: 4rem;
}

.btn-table-search {
  height: 4rem;
  margin-bottom: -0.7rem;
}

.btn-table-export {
  height: 4rem;
  width: 13.7rem;
  margin-bottom: -0.7rem;
}


.button-table-delete-container {
position: relative;
  .button-table-delete {
    @extend %btn;
    display: inline-flex;
    padding: 0.6rem 0.8rem 0.7rem 0.7rem;
    height: 3.2rem;

    border-radius: 0.4rem;
    border: 0.1rem solid rgb(219, 26, 23);

    background: $color-neutral-lightest;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: $color-neutral-lightest;
      border-color: rgb(219, 26, 23);
    }
    .delete-button-text {
      position: absolute;
      font-size: 1.3rem;
      left: 19px;
      color: rgb(219, 26, 23);
      text-align: center;
    }

    .icon-button-table-delete {
      width: 1.6rem;
      height: 1.5rem;
    }
  }
}

.button-table-edit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 8rem;
  .button-table-edit {
    @extend %btn;
    display: inline-flex;
    padding: 0.6rem 0.8rem 0.7rem 0.7rem;
    background-color: $color-secondary-light;
    border: 0.1rem solid $color-neutral-light;
    height: 3.5rem;
    
    margin-right: 0.7rem;
    margin-left: -1rem;
    .edit-btn-text {
      font-size: 1.3rem;
      position: absolute;
      text-align: center;
      left: 19px;
    }

    &:hover {
      background-color: $color-secondary-light;
    }
    .edit-btn-text {
      font-size: 1.3rem;
    }
  }
  .icon--edit-button {
    position: absolute;
    right: 41px;
    height: 16px;
    color: $color-neutral-darkest;
  }
}


.exclusion-alert {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
}

.exclusion-alert-warning {
  line-height: 26px;
  text-align: center;
  font-weight: normal;
  font-family: "Inter-Regular";
  font-size: 1.6rem;
}

.deletion-confirmation {
  color: $color-neutral-lightest;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform:none;
}