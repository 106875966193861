@use "../4-helpers" as *;

.c-forecast-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0.4rem;
  padding: 2rem;
  min-height: 95%;

  @include themed() {
    border: 0.1rem solid t("primary-border");
    background-color: t("primary-bg");
  }
}

.c-map-controls {
  width: 25%;
  margin: 0 1.6rem 0 3.2rem;
}

.map-toggle-group {
  flex-wrap: wrap;
  margin: 3.2rem 0;

  .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-radius: 10rem;
  }

  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border: 0.1rem solid $color-neutral-light;
    border-radius: 10rem;
  }

  .Mui-selected {
    background-color: $color-primary-base;
    color: $color-neutral-lightest;

    &:hover {
      background-color: $color-primary-dark;
    }
  }
}

.map-toggle-btn {
  margin: 0.4rem 1.2rem 0.4rem 0;
  padding: 0.4rem 1.6rem;
  border-radius: 10rem;
  border: 0.1rem solid $color-neutral-light;
  height: 3.4rem;
  color: $color-neutral-darkest;
  text-transform: none;

  &:hover {
    background-color: $color-primary-base;
    color: $color-neutral-lightest;
  }
}

.c-scale {
  margin: 3.2rem 0;
  flex-grow: 1;
}

.c-scale-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.scale-item {
  min-width: 1.5rem;
  width: 100%;
  height: 2rem;
}

p.scale-title {
  color: $color-neutral-dark;
}

.c-rainfall-scale {
  display: flex;
  flex-direction: row;
  margin-top: 2.4rem;
  flex-wrap: wrap;
}

.map-date-calendar-control {
  width: 100%;
  font-size: 2.4rem;
  border: 0.1rem solid $color-neutral-light;
  border-radius: 0.4rem;
  padding: 0.5rem;


  .MuiDayCalendar-weekDayLabel {
   color: #B4B4B4;
   font-size: 13px;
   font-family: "Inter-Regular", sans-serif;
   font-style: normal;
  }

  .MuiPickersDay-root {
    font-size: 1.6rem;
    color: #000;
    font-family: "Inter-Regular", sans-serif;
  }

  .MuiPickersDay-root:focus.Mui-selected {
    background-color: $color-secondary-lighter;
    color: $color-primary-base;
  }

  .MuiDayCalendar-weekContainer {
    .Mui-selected {
      background-color: $color-secondary-lighter;
      color: $color-primary-base;
    }
  }

  .MuiPickersCalendarHeader-root {
    padding-left: 1.2rem;
    padding-right: 0.6rem;
    margin-top: 1.4rem;
    align-items: center;
  }

  .MuiPickersCalendarHeader-labelContainer {
    font-size: 1.6rem;
    color: #000;
    font-family: "Inter-Regular", sans-serif;

    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.43px;
  }

  .MuiPickersYear-yearButton {
    font-family: $font-primary-regular;
    font-size: 1.4rem;
  }

  .MuiPickersYear-yearButton.Mui-selected,
  .MuiPickersYear-yearButton.Mui-selected:focus {
    background-color: $color-primary-base;
  }

  .MuiPickersYear-yearButton.Mui-selected:hover {
    background-color: $color-primary-dark;
  }

  .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
    color: $color-primary-base;
  }
}

p.map-controls-title {
  color: $color-primary-base;
}

.c-time-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  .MuiOutlinedInput-root {
    font-size: 1.6rem;
    min-width: 0;
    height: 4rem;
    margin-left: auto;
    width: 60%;
  }
}

.time-input {
  font-size: 1.6rem;
}

.time-input-label {
  font-size: 1.6rem;
  font-weight: 500;
}

.time-input-menu {
  font-size: 1.6rem;
}
