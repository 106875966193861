@use '../4-helpers' as *;

.c-recovery-page {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: radial-gradient(circle at 0 -5%, rgba($color-secondary-base, 0.1), rgba($color-secondary-base, 0.1) 10%, transparent 10%, transparent 20%, rgba($color-secondary-base, 0.1) 20%, rgba($color-secondary-base, 0.1) 30%, transparent 30%),
							radial-gradient(circle at 95% 125%, rgba($color-secondary-base, 0.1), rgba($color-secondary-base, 0.1) 10%, transparent 10%, transparent 20%, rgba($color-secondary-base, 0.1) 20%, rgba($color-secondary-base, 0.1) 30%, transparent 30%);
	background-color: $color-primary-light;
}

.c-recovery-content {
	display: flex;
	flex-direction: column;
	width: 60rem;
	background-color: $color-neutral-lightest;
	padding: 3.2rem;
	border-radius: .4rem;
	z-index: 100;
}

.circle {
	position: absolute;
	top: -63rem;
	left: -63rem;
	height: 126rem;
	width: 126rem;
	border-radius: 50%;
	background-color: $color-secondary-base;
}

.recovery-img {
	align-self: center;
	margin-bottom: 4rem;
}

.recovery-title {
	text-align: center;
	margin-bottom: 2.4rem;
}

.recovery-text {
	line-height: 2.5rem;
}

.c-tooltip {
	background-color: $color-helper-light;
	color: $color-neutral-darkest;
	font-size: 1.2rem;
	padding: .8rem;
	margin: .4rem;
	border-radius: .4rem;
}

.tooltip {
	display: inline;

	&__text {
		display: block;
		font-size: 1.2rem;
		font-family: $font-primary-regular;
	}

	&__list-item {
		font-size: 1.2rem;
		font-family: $font-primary-regular;
		list-style-position: inside;
	}
}

.timer {
	margin-left: 1.6rem;
}

.ReactInputVerificationCode__container {
	margin: 1.6rem 0 0 0;

	.ReactInputVerificationCode__item {
		box-shadow: inset 0 0 0 .1rem $color-neutral-light;
		width: 4.8rem;
		height: 4.8rem;

		&.is-active {
			box-shadow: inset 0 0 0 .2rem $color-primary-base;
		}
	}

}