@use "../4-helpers" as *;

.table-header {
  width: 100%;
  @include themed() {
    background-color: t("tb-bg");
  }
}

.header-summary {
  padding: 0;
  flex-direction: row;
}

.header-summary-content {
  margin: 0;
  padding: 2rem;
  justify-content: space-around;
  align-items: center;
  gap: 2.5rem;
}

.header-details {
  display: flex;
  padding: 2.4rem 0 2.4rem 4.8rem;
  @include themed() {
    background-color: t("tb-header-bg");
  }
}

.c-table-error {
  display: flex;
  flex-direction: column;
  height: 60rem;
  width: 40rem;
  align-items: center;
  justify-content: center;
  @include themed() {
    background-color: t("tb-bg");
  }

  &--no-result {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10rem;
    @include themed() {
      background-color: t("tb-bg");
    }
  }
}

.c-table-loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  @include themed() {
    background-color: t("tb-bg");
  }
}

.table-icon-wrapper {
  width: 12rem;
  height: 12rem;
  @include themed() {
    background-color: t("icon-table-bg");
  }

  &--no-result {
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;
  }
}

.table-error {
  &__title {
    text-align: center;
    margin: 1.2rem 0;
  }

  &__text {
    text-align: center;
    margin-bottom: 2.4rem;
  }
}

.c-status {
  padding: 0.8rem 1.6rem;
  border-radius: 2rem;
  &--active {
    @include themed() {
      color: t("tb-status-text");
      background-color: t("tb-status-active");
    }
  }

  &--inactive {
    @include themed() {
      color: t("tb-status-text");
      background-color: t("tb-status-inactive");
    }
  }
}

.c-input-search {
  display: flex;
  flex-grow: 1;
  min-width: 10rem;
  max-width: 100%;
  align-items: center;
  &--avatar {
    width: 6.3rem;
  }
}

.c-skeleton-table-pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 2rem 0;
}

.skeleton-table-line {
  height: 4rem;
  margin-bottom: 2.4rem;
  border-radius: 0.4rem;
}

.skeleton-table-circle-btn {
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  margin: 0 0.4rem;
}

.skeleton-table-rect-btn {
  height: 4rem;
  width: 19.2rem;
  border-radius: 0.4rem;
}

.table-search-width {
  width: 1rem;
}

.c-action-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
}

.date-picker-container {
  display: flex;
  gap: 1rem 0.5rem;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

.input-container {
  position: relative;
  display: inline-block;
}

.date-input {
  width: 18rem;
  height: 4rem;
  padding: 1.2rem 2.4rem;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.7rem;
}

.date-input::placeholder {
  color: #4120ce;
  font-size: 15px;
  font-style: normal;
  line-height: 100%;
  text-align: center;
  font-family: "Inter-Bold", sans-serif;
  
}

.date-input-medium {
  width: 25.2rem;
  height: 4.0rem;
  padding: 1.2rem 2.4rem;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.7rem;
}

.date-input-medium::placeholder {
  color: #4120ce;
  font-size: 15px;
  font-style: normal;
  line-height: 100%;
  text-align: center;
  font-family: "Inter-Bold", sans-serif;
}

.clear-button {
  position: absolute;
  right: 1rem;
  top: 49%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.close-button {
  height: 25px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.titleSeparator {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #292929;
  width: 12rem;

  font-size: 16px;
  font-style: normal;
  line-height: 120%;

  font-family: "Inter-Bold", sans-serif;
}

.calendar-icon {
  position: absolute;
  right: 75px;
  top: 54%;
  transform: translateY(-50%);
  cursor: pointer;
}

.column-date-start {
  border: 1px solid black;
  padding: 0;
}

.datetime,
.date_start,
.date_end,
.title,
.alert_type_name,
.risk_level {
  font-size: 1.3rem;
}
