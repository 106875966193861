@use "../4-helpers/" as *;

.modal {
  display: flex;
  padding: 0.6rem;

  @include themed() {
    background-color: t("primary-bg");
    color: t("primary-text");
  }

  &--medium {
    max-width: 100%;
    width: 60rem;
  }

  &--large {
    max-width: 100%;
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 1.6rem 2.6rem;
  }
}

.c-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2.6rem 2.6rem 2.6rem;
  gap: 3.2rem;
  &--justify-center {
    justify-content: center;
    align-items: center;
  }

  &--margin-right {
    margin-right: 1.1rem;
  }

  &::-webkit-scrollbar {
    width: 1.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-base;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-neutral-light;
    border-radius: 10rem;
  }
}

.recovery-modal-text {
  padding-top: 2.4rem;
  line-height: 2.56rem;
}

.c-controlBtns {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
}

.subtitle {
  margin: 3rem 0;
}

.recovery-result {
  &__text {
    line-height: 2.6rem;
  }
}
.Exclusion-Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  justify-content: center;
  gap: 3rem;
  .warning {
    margin-bottom: 5%;
  }

  h1 {
    width: 50rem;
    font-size: 3.6rem;
    color: $color-neutral-darkest;
    text-align: center;
    white-space: pre-line;
  }
  p {
    text-align: center;
    color: $color-neutral-darkest;
 
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}
.group-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  
  .btn-ModalOne {
    display: flex;
    padding: 1.6rem 3.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    align-self: stretch;
    cursor: pointer;
    border-radius: 0.4rem;
    margin: 0rem 3.4rem 0rem 3.4rem;
    background-color: $color-primary-base;

    color: $color-neutral-lightest;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform:none;
    &:hover {
      background-color: $color-primary-dark;
      color: $color-neutral-lightest;
    }
   
  }
  .btn-ModalTwo {
    display: flex;
    padding: 1.6rem 3.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    align-self: stretch;
    cursor: pointer;
    border-radius: 0.4rem;
    background: $color-neutral-lightest;
    margin: 0.1rem 3.4rem 3.7rem 3.4rem;
    color: $color-primary-base;

    font-weight: 700;
    font-size: 1.6rem;
    text-transform:none;
    border-radius: 0.4rem;

    border: 0.1rem solid $color-primary-base;
    &:hover {
      background: var --cittua-neutral-cittua-neutral-lightest;
      color: $color-primary-base;
    }
  }
}
.Modal-Two {
  width: 48.3rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  p {
    text-align: center;
    color: $color-neutral-darkest;

    font-family: "Inter-Regular";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
  .icon-two {
    margin-bottom: 5rem;
  }
}
.btn-Modal {
  display: flex;
  padding: 1.6rem 3.2rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  align-self: stretch;
  cursor: pointer;
  border-radius: 0.4rem;
  background-color: $color-primary-base;
  color: $color-neutral-lightest;
  font-size: 1.6rem;
  font-style: normal;
  margin: auto 3.1rem 3.1rem 3.1rem;

  &:hover {
    background-color: $color-primary-dark;
    color: $color-neutral-lightest;
  }
}

.modal-Details {
  min-height: 39.5rem;
  width: 48.3rem;
  border-radius: 4px;
  background: var(--cittua-neutral-cittua-neutral-lightest, #fff);

  .text-details-title {
    text-align: center;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    padding: 0%;

    white-space: pre-wrap;
    word-break: break-all;
    overflow: hidden;
    
    cursor: pointer;
  }

  .text-details {
    text-align: start;
    font-size: 1.6rem;
    line-height: 160%;
    font-weight: 400;
  }
  .details-group {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 3.2rem;
    gap: 0.7rem;
    p{
      color: #4120CE;
      font-weight: 600 ;
      font-size: 1.4rem;
      line-height: 160%;
    }

    span {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 160%;
      color: #292929;
    }
  }
}

.modal-come-back {
  width: 48.3rem;
  height: 52rem;
  border-radius: 4px;
  background: var(--cittua-neutral-cittua-neutral-lightest, #fff);

  .text-details-title {
    text-align: center;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    padding: 0rem 3.3rem 2rem 3.3rem ;
    white-space: pre-wrap;
    word-break: break-all;
    overflow: hidden;
    
    cursor: pointer;
  }

  .text-details {
    text-align: center;
    font-size: 1.6rem;
    padding-bottom: 2rem;
    line-height: 160%;
    font-weight: 400;
  }
}

.c-notification {
  min-height: 14rem;
  min-width: 60rem;
}

.c-notification-icon {
  background-color: $color-primary-base;
  border: 0.3rem solid $color-neutral-lightest;
  box-shadow: 0.1rem 0.3rem 0.4rem rgba(0, 0, 0, 0.3);
}

.c-notification-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.4rem;
}

.notification-title {
  margin-left: 1.2rem;
  color: $color-primary-base;
  font-family: $font-primary-bold;
  font-size: 2rem;
}

.notification-message {
  margin-left: 2rem;
}

:root {
  --toastify-toast-width: 65rem;
  --toastify-toast-min-height: 14rem;
}

.text-details-container {
  height: 10.6rem;
  overflow-y: hidden;

  .details-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.7rem;
    

  
  }
}
