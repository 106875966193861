@use "../4-helpers" as *;

.c-terms-and-privacy {
  padding: 5rem 5rem 8rem 5rem;
}

.terms-and-privacy {
  &__title {
    padding-bottom: 2rem;
  }
}

ol {
  counter-reset: item;
  list-style-type: none;
  list-style-position: inside;
}

.list-item:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.item {
  padding-left: 3rem;

  &--alpha-count {
    list-style-type: lower-alpha;
    &:before {
      content: normal;
    }
  }
}

.invisible-item:before {
  visibility: hidden;
}

.sub-item {
  padding-left: 3rem;

  &--alpha-count {
    list-style-type: lower-alpha;
    &:before {
      content: normal;
    }
  }

  &--roman-count {
    list-style-type: lower-roman;
    &:before {
      content: normal;
    }
  }
}

.frame {
  border: 0.1rem solid $color-neutral-darker;
  padding: 3rem;
}

table {
  width: 100%;
}

table,
th,
td {
  border: 0.1rem solid $color-neutral-darker;
  border-collapse: collapse;
}

.table-text {
  display: block;
}

td {
  padding: 2rem;
  text-align: center;
}

.justify-text {
  text-align: justify;
}

th {
  padding: 2rem;
}
