@use "variables" as *;

/*====================================================================*/
/*  Media query manager
/*====================================================================*/
/*====================================================================*/
//$breakpoint choices:
//
//- small-screen = 0 - 600px;
//- medium-screen = 600 - 900px;
//- general-screen = 900 - 1200px;
//- large-screen = 2200px+;
//
//$size choices:
//1em = 16px;
/*===================================================================*/

@mixin respond($breakpoint) {
  @if $breakpoint == medium-screen {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }

  @if $breakpoint == general-screen {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }

  @if $breakpoint == large-screen {
    @media (min-width: 137.5em) {
      @content;
    } //2200px
  }
}

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

$break: (
  s: 576px,
  m: 768px,
  l: 992px,
  xl: 1200px,
);

@mixin responsiveMaxWidth($key) {
  $size: map-get($break, $key);

  @if $size {
    @media (max-width: $size) {
      @content;
    }
  } @else {
    @media (max-width: #{$key}px) {
      @content;
    }
  }
}

@mixin responsiveMinWidth($key) {
  $size: map-get($break, $key);

  @if $size {
    @media (min-width: $size) {
      @content;
    }
  } @else {
    @media (min-width: #{$key}px) {
      @content;
    }
  }
}
