@use "../4-helpers" as *;

.c-map {
  position: relative;
  height: 100%;
  width: 75%;
  border-radius: 0.4rem;
  border: 0.1rem solid $color-neutral-light;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
}

.skeleton-map {
  height: 100%;
  width: 100%;
}

.c-last-update {
  position: absolute;
  top: 95%;
  left: 0.5rem;
  z-index: 1000;
  background-color: $color-neutral-lightest;
  padding: 0.4rem 0.8rem;
  border-radius: 3rem;
}

.c-loading-map-info {
  top: 2vh;
  left: 70%;
  z-index: 1000;
  background-color: $color-neutral-lightest;
  padding: 0.4rem 0.8rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.loading-map-text {
  margin-right: 1rem;
}

.loading-map-icon {
  color: $color-primary-base;
}

.c-map-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.c-top-msg {
  position: relative;
  display: flex;
  flex-grow: 1;
  z-index: 1000;
  justify-content: flex-end;
  padding: 2rem;
}

.container-select-control-map {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.select-area-map {
  border-radius: 4px;
  border: 1px solid #4120ce;

  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: $font-primary-regular;
  font-size: 1.4rem;
  color: #4120ce !important;
  font-weight: 700;
  line-height: 100%;

  height: 4.2rem;
  cursor: pointer;
}

.threedots-map {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  border: 1px solid var(--Cittua-Primary-Cittua---Primary---Pure, #4120ce);
  cursor: pointer;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  color: var(--Cittua-Neutral-Cittua---Neutral---Darkest, #292929);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 0.1rem;
 
}

.map-legend-button-container {
  position: relative;
  bottom: -1rem;
  cursor: pointer;
}

.map-calendar-button-container {
  position: relative;
  top: 1.9rem;
  cursor: pointer;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
}