.c-login-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: 100vh;
}

.c-right {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.c-left {
	width: 50vw;
	height: 100vh;
	position: relative;
	background-image: url('../../assets/img/grafismos.png'), url('../../assets/img/cittua_login_bg.png');
	background-repeat: no-repeat, no-repeat;
	background-size: cover, cover;
	background-position: -25rem 0, -10rem 0;
}


.c-login-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%; 
	max-width: 43.9rem;
	padding: 20px;
  
	@media (max-width: 768px) {
	  width: 100%; 
	}
	@media (min-width: 1366px) {
	  width: 100%; 
	   img{
		max-width: 50%
	   }
	}
	@media (min-width:2560px) {
		form {
		  max-width: 1200px; 
		}
	}
}

.c-recaptcha {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 10rem;
	margin: 2rem 0;
}

.logo-img {
	width: 20rem;
	height: 5.5rem;
}

.c-tenant-selection {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.selection-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 43.9rem;
}

.tenant-selection-title {
	margin-top: 1rem;
	text-align: center;
}